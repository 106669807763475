// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,

  ServicePartnerDataURL: "https://apim-internal-qat.academicpartnerships.com/PDMWebApi/",
  //preceptorServiceURL : 'https://preceptorqaservice.academicpartnerships.com/',
  preceptorServiceURL: 'https://azr-bte-sol-qat-api-preceptor.azurewebsites.net/',
  //Azure AD App Configurations
  adAauthority: 'https://login.microsoftonline.com/8720e855-7e28-49ff-a1a7-8d5e66b5659e/',
  adClientId: "f19d1b38-2055-4cf6-bd2a-c753f2a229e2",
  adTenantId: "8720e855-7e28-49ff-a1a7-8d5e66b5659e",
  adPostLogoutRedirectUri: "https://preceptor.qat.risepoint.com/",
  adRedirectUrl: 'https://preceptor.qat.risepoint.com/',
  apiConfig: {
    scopes: ['api://AP.Preceptor.API-QA/access_as_user']
  }
};

